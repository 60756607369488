<template>
  <div>
    <ResendNotificationModal 
      :proposalsSelected="proposalsSelected" 
      @close-modal-cancel="proposalsSelected = {}"
      @close-modal-resend="resendNotification(proposalsSelected._id)"
    />
    <InfoModal
    :contentData="infoModalData"
    />
    <div class="div-companies content-wrapper">
      <section class="content-header p-1 px-2">
        <div class="div-header container-fluid">
          <div style="border-bottom: 1px solid #0001" class="row mb-2">
            <div
              class="col-sm-12 d-flex justify-content-between align-items-center"
            >
              <h4 style="color: #0007" class="mt-3">
                <i class="fa fa fa-file-contract"></i>
                Contratos
              </h4>
            </div>
          </div>
        </div>
      </section>

      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="card title-height">
              <div class="card-header">
                <h3 class="card-title">
                  Total de contratos:
                  <b>{{ totalDocuments }}</b>
                </h3>
               <div class="card-tools">
                 <div class="input-group input-group-sm" style="width: 400px;">
                  <div class="dropdown">
                    <button class="btn dropdown-toggle mr-1 border" type="button" data-bs-toggle="dropdown"> Empresas
                    <span class="caret"></span></button>
                    <ul class="dropdown-menu">
                      <li class="ml-2 mb-1 mr-2" style="color:#343a40;" @click="getProposals(actualFilterName, '', '')">Todas</li>
                      <hr>
                      {{ actualFilterName }}
                      <li class="ml-2 mb-1 mr-2" style="color:#1b4068;" @click="getProposals(actualFilterName, '', 'TALLOS')" > TALLOS </li> 
                      <li class="ml-2 mb-1 mr-2" style="color:#1b4068;" @click="getProposals(actualFilterName, '', 'associated')"> Associados </li> 
                    </ul>
                  </div>
                    <div class="dropdown">
                      <button class="btn dropdown-toggle mr-1 border" type="button" data-bs-toggle="dropdown"> Status
                      <span class="caret"></span></button>
                      <ul class="dropdown-menu">
                        <li class="ml-2 mb-1 mr-2" style="color:#343a40;" @click="getProposals(actualFilterName, '', '')">Todos</li>
                        <li class="ml-2 mb-1 mr-2" style="color:#2b5e94;" @click="getProposals(actualFilterName, 'proposal_sent', '')" >Proposta Enviada</li> 
                        <li class="ml-2 mb-1 mr-2" style="color:#208a39;" @click="getProposals(actualFilterName, 'payment_completed', '')">Pagto Concluido</li> 
                        <li class="ml-2 mb-1 mr-2" style="color:#17a2b8;" @click="getProposals(actualFilterName, 'account_created', '')">Conta Criada</li> 
                        <li class="ml-2 mb-1 mr-2" style="color:#6c757d;" @click="getProposals(actualFilterName, 'proposal_cancelled', '')">Cancelada</li> 
                        <hr>
                      </ul>
                    </div>
                    <button
                      class="btn btn-default btn-sm mr-1"
                      title="atualizar"
                      @click="getProposals('', '', '')"
                    >
                      <i class="fas fa-sync"></i>
                    </button>
                    <button
                      class="btn btn-default btn-sm mr-1"
                      title="exportar"
                      @click="exportContracts()"
                    >
                      <div v-if="spinner" class="spinner-border spinner-size" role="status" />
                      <i v-else class="fas fa-file-excel"></i>
                    </button>
                    <input
                      v-model="searchProposals"
                      title="Pesquisar Empresa"
                      type="text"
                      name="table_search"
                      class="form-control float-right input-search"
                      :placeholder="`Pesquisar Contrato por nome`"
                    />
                    <i
                      class="fas fa-search lupa-style"
                      title="Perquisar contrato"
                      @click="getProposals(searchProposals, actualFilterStatus, actualFilterChannel)"
                    ></i>
                  </div>
                </div>
              </div>


              <div class="card-body table-responsive p-0">
                <table
                  class="table table-hover table-stripped table-head-fixed"
                  v-if="!loading"
                >
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Nome</th>
                      <th>Email</th>
                      <th class="text-center">Empresa</th>
                      <th class="text-center">Registro em</th>
                      <th class="text-center">Status</th>
                      <th class="text-center">
                        Ações
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(proposals, i) in proposals" :key="i">
                      <td>{{ proposals._id }}</td>
                      <td>
                        <div class="d-flex justify-content-start">
                          <p :title="proposals.name">
                            {{ truncate(proposals.name, 15) }}
                          </p>
                        </div>
                      </td>
                      <td class="td-last-accsess">
                        <p :title="proposals.email">
                          {{ truncate(proposals.email, 15) }}
                        </p>
                      </td>
                      <td class="text-center">
                        <p :title="proposals.sales_channel">
                          {{ mapSalesChannel(proposals.sales_channel)}}
                        </p>
                      </td>
                      <td class="text-center">
                        {{proposals.createdAt ? formatData(proposals.createdAt, 'L') : '--------------------------'}}
                      </td>
                      <td class="text-center">
                        <span v-if="proposals.proposal_status === 'proposal_sent'" class="badge bg-primary">Proposta Enviada</span>
                        <span v-if="proposals.proposal_status === 'account_created'" class="badge bg-info">Conta Criada</span>
                        <span v-if="proposals.proposal_status === 'payment_completed'" class="badge bg-success">Pagto Concluido</span>
                        <span v-if="proposals.proposal_status === 'proposal_cancelled'" class="badge bg-secondary status-badge">Proposta Cancelada</span>
                      </td>
                      <td class="text-center">
                        <button
                          title="Ver detalhes"
                          @click="redirectToOportunities(proposals)"
                          type="button"
                          class="btn btn-outline-primary btn-sm mr-2">
                          <i class="fa fa-search"></i>
                        </button>
                        <button
                          title="Reenviar notificações"
                          @click="proposalsSelected = proposals"
                          :disabled="proposals.proposal_status === 'proposal_cancelled'"
                          data-toggle="modal" 
                          data-target="#resendNotificationModal" 
                          type="button"
                          class="btn btn-outline-primary btn-sm mr-2">
                          <i class="fa fa-share"></i>
                        </button>
                        <button
                          title="Gerar link"
                          @click="generateLink(proposals._id)"
                          :disabled="['proposal_cancelled', 'payment_completed'].includes(proposals.proposal_status)"
                          type="button"
                          class="btn btn-outline-warning btn-sm">
                          <i class="fa fa-copy"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div
                  v-if="loading"
                  class="d-flex justify-content-center align-items-center mt-2 mb-2"
                >
                  <div class="spinner-border spinner-size" role="status" />
                  <span class="ml-2">Carregando Propostas...</span>
                </div>
              </div>
              <section class="buttons d-flex justify-content-center mb-3">
                <nav class="mt-3 d-flex">
                  <button
                    :class="'btn btn-outline-primary'"
                    @click="firstPage()"
                    :disabled="currentPage === 1 ? true : false"
                  >
                    <i class="fa fa fa-angle-double-left"></i>
                  </button>
                  <button
                    :class="'btn btn-outline-primary'"
                    @click="previousPage()"
                    :disabled="currentPage === 1 ? true : false"
                  >
                    <i class="fa fa fa-angle-left"></i>
                  </button>
                  <button
                    :class="'btn btn-outline-primary'"
                    @click="previousPage()"
                    :disabled="true"
                  >
                    <span> {{ currentPage }}</span>
                  </button>
                  <button
                    :class="'btn btn-outline-primary'"
                    :disabled="
                      this.totalDocuments > this.perPage * this.currentPage
                        ? false
                        : true
                    "
                    @click="nextPage()"
                  >
                    {{ currentPage + 1 }}
                  </button>
                  <button
                    :class="'btn btn-outline-primary'"
                    :disabled="
                      this.totalDocuments > this.perPage * this.currentPage
                        ? false
                        : true
                    "
                    @click="nextPage()"
                  >
                    <i class="fa fa fa-angle-right"></i>
                  </button>
                  <button
                    :class="'btn btn-outline-primary'"
                    :disabled="
                      this.totalDocuments > this.perPage * this.currentPage
                        ? false
                        : true
                    "
                    @click="lastPage()"
                  >
                    <i class="fa fa fa-angle-double-right"></i>
                  </button>
                </nav>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment-timezone'
import "moment/locale/pt-br.js"
import route from '../../../router/router';
import { truncate } from '../../../config/global';
export default {
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      proposals: [],
      totalDocuments: 0,
      loading: true,
      actualFilterName: '',
      actualFilterStatus: '',
      actualFilterChannel: '',
      searchProposals: '',
      spinner: false,
      proposalsSelected: {},
      truncate,
      infoModalData: {
        title: 'default title',
        bodyText: 'default content'
      }
    };
  },
  components: {
    ResendNotificationModal: () =>
      import(
        /* webpackChunkName: "resend-notification-modal" */ "@/components/shared/components/resend-notification-modal/index.vue"
      ),
      InfoModal: () =>
      import(
        /* webpackChunkName: "info-modal" */ "@/components/shared/components/alert-modals/blue-info-modal.vue"
      ),
  },
  methods: {
    formatData(data, format) {
		  return moment(data).format(format)
	  },
    mapSalesChannel(salesChannel) {
    const channelMap = {
      'associated': 'ASSOCIADO',
    };
    return channelMap[salesChannel] || salesChannel;
  },
    async resendNotification(proposalId) {
      this.$tallos_admin_api.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${this.$store.getters.tokenJWT}`;

      try {
        await this.$tallos_admin_api
        .post(`proposals/${proposalId}/resendNotifications`).then((res) =>{
          this.proposalsSelected = {}
          this.$toasted.global.defaultSuccess({
            msg: "Noticações enviadas com sucesso"
          });
        })
        .catch(({response}) => {
          this.infoModalData.title = 'Erro ao realizar redirecionamento'
          this.infoModalData.bodyText = response.data.message
          $('#infoModal').modal('show')
        })
      } catch (error) {
        this.infoModalData.title = 'Erro ao realizar redirecionamento'
        this.infoModalData.bodyText = 'Erro ao reenviar notificações.'
        $('#infoModal').modal('show')
      }
    },

    async generateLink(proposalId) {
      this.$tallos_admin_api.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${this.$store.getters.tokenJWT}`;

      try {
        await this.$tallos_admin_api
          .get(`proposals/${proposalId}/generateLink`).then((result) => {
            navigator.clipboard.writeText(result.data.link);
            this.$toasted.global.defaultSuccess({
              msg: "Novo link copiado com sucesso"
            });
          })
          .catch(({ response }) => {
            this.infoModalData.title = 'Erro ao criar o link'
            this.infoModalData.bodyText = response.data.message
            $('#infoModal').modal('show')
          })
      } catch (error) {
        this.infoModalData.title = 'Erro ao criar o link'
        this.infoModalData.bodyText = 'Erro inesperado, por favor realizar contato com o suporte.'
        $('#infoModal').modal('show')
      }
    },

    redirectToOportunities(data){
      this.$store.state.proposalData = data
      route.push('/admin/opportunities')
    },

    firstPage() {
      this.currentPage = 1;
      this.getProposals(this.actualFilterName, this.actualFilterStatus, this.actualFilterChannel);
    },

    lastPage() {
      this.currentPage = Math.ceil(this.totalDocuments / this.perPage, this.actualFilterChannel);
      this.getProposals(this.actualFilterName, this.actualFilterStatus, this.actualFilterChannel);
    },

    nextPage() {
      if (this.totalDocuments >= this.perPage * this.currentPage) {
        this.currentPage++;
        this.getProposals(this.actualFilterName, this.actualFilterStatus, this.actualFilterChannel);
      }
    },

    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.getProposals(this.actualFilterName, this.actualFilterStatus, this.actualFilterChannel);
      } else {
        this.currentPage = 1;
        this.getProposals(this.actualFilterName, this.actualFilterStatus, this.actualFilterChannel);
      }
    },
    getProposals(filterName, filterStatus, filterChannel) {
       if(filterName !== this.actualFilterName || filterStatus !== this.actualFilterStatus || filterChannel !== this.actualFilterChannel){
        this.currentPage = 1
        this.actualFilterName = filterName
        this.actualFilterStatus = filterStatus
        this.actualFilterChannel = filterChannel

      }
      this.loading = true;
      this.$tallos_admin_api.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${this.$store.getters.tokenJWT}`;
      this.$tallos_admin_api
        .get(`proposals/getContracts?perPage=${this.perPage}&page=${this.currentPage}&filterName=${this.actualFilterName}&filterStatus=${this.actualFilterStatus}&filterChannel=${this.actualFilterChannel}`)
        .then((res) => {
          this.proposals = res.data.content;
          this.totalDocuments = res.data.totalDocuments;
          this.loading = false;
        });
    },
    exportContracts() {
      this.spinner = true
      this.$tallos_admin_api.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${this.$store.getters.tokenJWT}`;
      this.$tallos_admin_api
        .get(`proposals/exportContract`, { responseType: 'blob' })
        .then((res) => {
          if (res.status === 204) {
            this.spinner = false
            this.$toasted.global.defaultSuccess({ msg: 'relatorio enviado por email' })
          }
          else {
            this.spinner = false
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.download = 'Tallos Admin - Contracts.xlsx';
            link.click();
          }
        })
        .catch((err) => {
          this.spinner = false
          this.$toasted.global.defaultError({ msg: 'erro na exportação das propostas.' })
        })
    }
  },
  created() {
    this.loading = true;
    this.$tallos_admin_api.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.$store.getters.tokenJWT}`;
    this.$tallos_admin_api
      .get(`proposals/getContracts?perPage=${this.perPage}&page=${this.currentPage}`)
      .then((res) => {
        this.proposals = res.data.content;
        this.totalDocuments = res.data.totalDocuments;
        this.loading = false;
      });
  },
};
</script>

<style scoped>
.div-companies {
  background-color: #fff;
}

.badge {
  width: 100px;
}

.bg-voip {
  background: #9c27b0;
  color: #fff;
}

.lupa-style {
  position: absolute;
  right: 10px;
  top: 8px;
  color: #0008;
  cursor: pointer;
}

.input-search {
  border-radius: 3px !important;
}

.select-review-companies {
  width: 15px !important;
  color: #0009;
  background: #fff;
  border: none;
  outline: none !important;
}

.td-last-accsess {
  font-weight: 500;
  text-align: start;
}

.span-engaged-disengaged {
  display: flex;
  justify-content: center;
  align-items: center;
}

.engaged {
  font-weight: 600;
  font-size: 14px;
  color: rgb(40, 167, 69);
}

.disengaged {
  font-weight: 600;
  font-size: 14px;
  color: rgb(245, 81, 31);
}

.dropdown-menu li {
  outline: none;
  border: none;
  background: white;
  color: #0652dd;
  font-weight: 600;
  cursor: pointer;
}

.dropdown-menu li:hover {
  background: #ddd;
}

.title-height {
  height: 600px;
}

.spinner-size {
  max-height: 20px;
  max-width: 20px;
}
.redirectButton:hover{
  cursor: pointer;
}
.status-badge{
  width: 120px !important;
}
</style>
